export const FETCH_GET_QUOTE_PENDING = 'quote//FETCH_GET_QUOTE_PENDING'
export const FETCH_GET_QUOTE_DONE = 'quote//FETCH_GET_QUOTE_DONE'
export const FETCH_GET_QUOTE_ERROR = 'quote//FETCH_GET_QUOTE_ERROR'

export function pending() {
  return {
    type: FETCH_GET_QUOTE_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_QUOTE_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_QUOTE_ERROR,
    payload: {
      err,
    },
  }
}

export function getQuoteIdData(store) {
  return store.quote.data.id || ''
}

export function getPaymentData(store) {
  return store.quote.data.payment || {}
}

export function getElementsData(store) {
  return store.quote.data.elements || []
}

export function getDocumentsData(store) {
  const documents = {}
  if (store.quote.data.documents) {
    store.quote.data.documents.forEach((document) => {
      documents[document.type] = document.url
    })
  }
  return documents
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_QUOTE_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_QUOTE_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_QUOTE_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
