export const SET_IS_DOC_DOWNLOADED = 'summaryPage//SET_IS_DOC_DOWNLOADED'
export const RESET_DOC_DOWNLOADED_INFORMATIONS =
  'summaryPage//RESET_DOC_DOWNLOADED_INFORMATIONS'

export function setDocDownloadInformation(data) {
  return {
    type: SET_IS_DOC_DOWNLOADED,
    payload: data,
  }
}

export function resetDocumentsInformations() {
  return {
    type: RESET_DOC_DOWNLOADED_INFORMATIONS,
  }
}

export function getDocDownloadInformation(store) {
  return store.summaryPage.documentsData
}

const initialState = {
  documentsData: [
    { id: 1, isDownloaded: false },
    { id: 2, isDownloaded: false },
  ],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_DOC_DOWNLOADED:
      return {
        documentsData: [...state.documentsData].map((item, index) => {
          if (index + 1 === action.payload.id) {
            return {
              ...item,
              isDownloaded: true,
            }
          }
          return item
        }),
      }
    case RESET_DOC_DOWNLOADED_INFORMATIONS:
      return { ...initialState }
    default:
      return state
  }
}
