import { createStore, combineReducers } from 'redux'

import formReducer from './features/form'
import quoteReducer from './features/quote.redux'
import documentBundleReducer from './features/document-bundle.redux'
import policyReducer from './features/policy.redux'
import orderReducer from './features/order.redux'
import summaryPageReducer from './features/summary-page.redux'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    quote: quoteReducer,
    documentBundle: documentBundleReducer,
    policy: policyReducer,
    order: orderReducer,
    summaryPage: summaryPageReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }
