export const SET_STEP_DATA = 'form//SET_STEP_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export const SET_DATA_MODELS = 'form//SET_DATA_MODELS'

export function setStepData(step, data) {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  }
}

export function setDataModels(data) {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  }
}

export function getDataModels(store) {
  return store.form.dataModels
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getStepData(store, step) {
  return store.form.stepData[step]
}

export function getFormData(store) {
  return store.form
}

const initialState = {
  stepData: {},
  dataModels: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        stepData: {},
      }

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      }
    case SET_DATA_MODELS:
      return {
        ...state,
        dataModels: action.payload,
      }

    default:
      return state
  }
}
